<template>
  <div>
    <b-row class="d-flex align-items-center">
      <b-col>
        <b-input-group>
          <b-form-input
            v-model="search.keyword"
            placeholder="Firma Adı..."
            @keydown.enter="searchData"
          />
          <b-input-group-append>
            <b-button
              variant="primary"
              @click="searchData"
            >
              <FeatherIcon icon="SearchIcon" />
              Ara
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="auto">
        <b-form-checkbox
          :checked="detailSearch"
          name="check-button"
          switch
          inline
          @change="detailToggle"
        >
          Detaylı Arama
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row
      v-if="detailSearch"
      class="mt-2"
    >
      <b-col
        cols="12"
        md="4"
      >
        <interview-subject />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <interview-status />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <meets />
      </b-col>
      <b-col>
        <users />
      </b-col>
      <b-col>
        <start-date />
      </b-col>
      <b-col>
        <end-date />
      </b-col>
      <b-col
        cols="12"
        class="text-center"
      >
        <b-button
          variant="primary"
          @click="detailSearchData"
        >
          <FeatherIcon icon="FilterIcon" /> Filtrele
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol, BFormCheckbox, BFormInput, BButton, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import InterviewSubject from '@/views/Admin/Interviews/Index/elements/InterviewSubject.vue'
import InterviewStatus from '@/views/Admin/Interviews/Index/elements/InterviewStatus.vue'
import Meets from '@/views/Admin/Interviews/Index/elements/Meets.vue'
import Users from '@/views/Admin/Interviews/Index/elements/Users.vue'
import StartDate from '@/views/Admin/Interviews/Index/elements/StartDate.vue'
import EndDate from '@/views/Admin/Interviews/Index/elements/EndDate.vue'

export default {
  name: 'FilterData',
  components: {
    BRow,
    BCol,
    BFormCheckbox,
    BFormInput,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    InterviewSubject,
    InterviewStatus,
    Meets,
    Users,
    StartDate,
    EndDate,
  },
  props: {
    searchData: {
      type: Function,
      required: true,
    },
    detailSearchData: {
      type: Function,
      required: true,
    },
  },
  computed: {
    search() {
      return this.$store.getters['interviews/search']
    },
    detailSearch() {
      return this.$store.getters['interviews/detailSearch']
    },
  },
  methods: {
    detailToggle() {
      const status = !this.detailSearch
      this.$store.commit('interviews/SET_DETAIL_SEARCH', status)
    },
  },
}
</script>
