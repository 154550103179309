<template>
  <b-form-group
    label="Görüşme Durumu"
    label-for="title"
  >
    <v-select
      v-model="filterData.status"
      :options="statuses"
      label="title"
      :reduce="item => item.id"
      placeholder="Seçiniz"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'InterviewStatus',
  components: {
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      statuses: [
        {
          id: '0',
          title: 'Açık',
        },
        {
          id: '1',
          title: 'Kapalı',
        },
      ],
    }
  },
  computed: {
    filterData() {
      return this.$store.getters['interviews/filterData']
    },
  },
}
</script>
