<template>
  <b-form-group
    label="Temsilci"
    label-for="id_users"
  >
    <v-select
      v-model="filterData.id_users"
      :options="users"
      label="title"
      :reduce="item => item.id"
      placeholder="Seçiniz"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Users',
  components: {
    BFormGroup,
    vSelect,
  },
  computed: {
    filterData() {
      return this.$store.getters['interviews/filterData']
    },
    users() {
      return this.$store.getters['users/dataList']
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('users/getDataList', {
        select: [
          'users.id AS id',
          'users.name AS title',
        ],
      })
    },
  },
}
</script>
